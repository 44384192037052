@font-face {
  font-family: 'ProximaNova-Bold';
  src: local('ProximaNova-Bold'), url('../../assets/fonts/ProximaNova-Bold.otf') format('woff');
}
@font-face {
  font-family: 'ProximaNova-Regular';
  src: local('ProximaNova-Regular'), url('../../assets/fonts/ProximaNova-Regular.otf') format('woff');
}
@font-face {
  font-family: 'ProximaNova-Thin';
  src: local('ProximaNova-Regular'), url('../../assets/fonts/ProximaNova-Thin.otf') format('woff');
}
@font-face {
  font-family: 'TCCC-UnityHeadline-Bold';
  src: local('TCCC-UnityHeadline-Bold'), url('../../assets/fonts/TCCC-UnityHeadline-Bold.woff') format('woff');
}
@font-face {
  font-family: 'TCCC-UnityHeadline-Regular';
  src: local('TCCC-UnityHeadline-Regular'), url('../../assets/fonts/TCCC-UnityHeadline-Regular.woff') format('woff');
}
