@import '../style/index.scss';

.App {
  text-align: center;
  font-family: ProximaNova-Bold;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
}


body {
  width:100vw;
  height:100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

#root {
  width:100vw;
  height:100vh;
}

@media only screen and (min-width: 1000px) {
  body {
    font-size: 24px;
  }
}