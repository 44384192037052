
:root {
  --margin-extra-tiny: 12px;
  --margin-tiny: 0.4em;
  --margin-small: 0.7em;
  --margin-medium: 96px;
  --margin-large: 124px;
  --margin-huge: 186px;
  --margin-gigantic: 202px;
}
