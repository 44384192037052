:root{
  --white: #ffffff;
  --NL-primary: #F46B23;
  --NL-secondary: #E05B10;
  --BE-primary: #BF0005;
  --BE-secondary: #BA3034;
  --main-color: #00a8ac;
  --container-background: rgba(0, 153, 167, 0.8);
  --primary-text: #646568;
  --light-blue: #3ab7ba;
  --extra-light-red: #ffced0;
  --light-red: #fc454a;
}
