:root {
  --appear-3s: appear 0.4s linear 3s 1 forwards;
  --appear-2s: appear 0.4s linear 1.5s 1 forwards;
  --slide-in-left: slideInLeft 0.3s linear 0.7s 1 forwards;
  --slide-in-right: slideInRight 0.3s linear 1s 1 forwards;

}

@keyframes appear {
  0%  {transform: scale(0);}
  100% {transform: scale(1);}
}

@keyframes slideInLeft {
  0% {transform: translateX(0%);}
  100%  {transform: translateX(100%);}
}

@keyframes slideInRight {
  0% {transform: translateX(0%);}
  100%  {transform: translateX(-100%);}
}