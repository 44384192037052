:root {
  --font-countdown-tiny: 0.2em;
  --font-countdown-small: 0.5em;
  --font-countdown-regular: 1em;
  --font-countdown-medium: 2.4em;
  --font-countdown-large: 4em;
  --font-countdown-extra-large: 6em;
  --font-standing-regular: 1.7em;
  --font-standing-medium: 1.7em;
  --font-standing-large: 4.8em;
  --font-live-small: 1.2em;
  --font-live-regular: 1.8em;
  --font-live-medium: 3.1em;
  --font-live-large: 5em;
  --font-schedule-regular: 0.6em;
  --font-schedule-medium: 1em;
  --font-schedule-large: 4em;
}
